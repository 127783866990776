export default {
  path: "about-us",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/AboutUs/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        aboutUs: () => import("../../views/Home/views/AboutUs/List/Index"),
      },
    },
  ],
};
