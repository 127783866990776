// Global route list
const routeList = {
};

// Get route from list by key
const getRoute = (key, relative = true) => {
  // Default route
  let result = "/";

  if (routeList[key]) {
    // Route from list
    result = routeList[key];

    // Remove first slash if it's not relative
    if (!relative) {
      result = result.replace("/", "");
    }
  }

  return result;
};

export default getRoute;
