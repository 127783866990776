// import requestLogin from "../../models/request/login";
import router from "../../router/index";
import helper from "@/plugins/globalHelper.js";

import { axiosClient } from "../axios";

const API_URL = process.env.VUE_APP_API_URL;
const TOKEN = localStorage.getItem("accessToken") ?? null;

const defaultConfig = {
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
};

const get = (address, config, onSuccess, onError, onFinish) => {
  axiosClient
    .get(API_URL + address, defaultConfig)
    .then((response) => onSuccess(response))
    .catch((error) => processErrors(error, "get", onError))
    .then(() => onFinish());
};

const post = (address, payload, config, onSuccess, onError, onFinish) => {
  axiosClient
    .post(API_URL + address, payload, defaultConfig)
    .then((response) => onSuccess(response))
    .catch((error) => processErrors(error, "post", onError))
    .then(() => onFinish());
};

const put = (address, payload, config, onSuccess, onError, onFinish) => {
  const requestData = getRequestData(payload);
  axiosClient
    .put(API_URL + address, requestData, defaultConfig)
    .then((response) => onSuccess(response))
    .catch((error) => processErrors(error, "put", onError))
    .then(() => onFinish());
};

const remove = (address, config, onSuccess, onError, onFinish) => {
  axiosClient
    .delete(API_URL + address, defaultConfig)
    .then((response) => onSuccess(response))
    .catch((error) => processErrors(error, "remove", onError))
    .then(() => onFinish());
};

const processErrors = (error, sender, onError) => {
  if (error) {
    if (error.response) {
      let status = error.response.status;
      switch (status) {
        case 401:
          localStorage.removeItem("accessToken");
          router.push("/login");
          break;
        case 403:
          router.push("/access-denied");
          break;
        case 405:
          router.push("/select-server");
          break;
        case 404:
          router.push("/not-found");
          break;
        default:
          console.warn("Bilinmeyen Hata - Hata Kodu: " + status);
          break;
      }
    } else {
      console.warn(error, "İÇ HATA: api=>" + sender);
    }
  } else {
    console.warn("Bilinmeyen Hata");
  }

  if (onError) onError(error);
};

const getRequestData = (data) => {
  if (data) {
    const imageKeys = ["image"];
    let postFormData = false;

    imageKeys.forEach((key) => {
      if (
        data.hasOwnProperty.call(Object.entries(data), key) &&
        data[key] &&
        data[key] !== null
      ) {
        postFormData = true;
      }
    });

    if (postFormData) {
      return helper.objectToFormData(data, null, ["fileData", "imageData"]);
    } else {
      return data;
    }
  } else {
    return "";
  }
};

export const apiService = {
  get,
  post,
  put,
  remove,
};
