export default {
  path: "users",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Users/Index"),
  },
  children: [
    {
      path: "detail/:id",
      meta: {},
      components: {
        users: () => import("../../views/Home/views/Users/Detail/Index"),
      },
    },
  ],
};
