import Vue from "vue";
import VueRouter from "vue-router";
import constant from "../plugins/globalVariables";
import home from "./routes/home";
import about from "./routes/about";
import contract from "./routes/contract";
import setting from "./routes/setting";
import sss from "./routes/sss";
import transation from "./routes/transation";
import users from "./routes/users";
import commission from "./routes/commission";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/Login/Index"),
  },
  {
    path: "",
    component: () => import("../views/Home/Index"),
    children: [
      {
        name: "home",
        path: "",
        components: {
          home: () => import("../views/Home/views/Dashboard/Index"),
        },
      },
      home,
      about,
      contract,
      setting,
      sss,
      transation,
      users,
      commission
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: constant.BASE_URL,
  routes: routes,
});

const documentTitle = `${constant.APP_TITLE} | v${constant.APP_VERSION} (${constant.APP_DATE})`;
const authRoutes = ["login"];

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || documentTitle;

  if (localStorage.getItem("accessToken")) {
    if (to.name == "login") {
      router.replace("/").catch(() => {});
    } else {
      next();
    }
  } else {
    if (!authRoutes.includes(to.name)) {
      router.replace({ name: "login" }).catch(() => {});
    } else {
      next();
    }
  }
});

export default router;
