export default {
  path: "home-page",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/HomePage/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        homepage: () => import("../../views/Home/views/HomePage/List/Index"),
      },
    },
  ],
};
