import Vue from "vue";
import Vuex from "vuex";

// begin::Modules
import pages from "./modules/pages";
import auth from "./modules/auth";
import notification from "./modules/notification";
import bankAccount from "./modules/bankAccount";
import members from "./modules/members";
import transaction from "./modules/transaction";
import commission from "./modules/commission";
import stat from "./modules/stat";
// end::Modules

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    pages,
    auth,
    notification,
    bankAccount,
    members,
    transaction,
    commission,
    stat
  },
});
