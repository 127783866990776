export default {
  path: "sss",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/SSS/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        sss: () => import("../../views/Home/views/SSS/List/Index"),
      },
    },
  ],
};
