export default {
  path: "transactions",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Transaction/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        transaction: () => import("../../views/Home/views/Transaction/List/Index"),
      },
    },
  ],
};
