import { apiService } from "../../services/api";

const state = {
  accessToken: null,
  refreshToken: null,
};

const mutations = {
  setToken: (state, payload) => {
    if (payload?.access_token) {
      localStorage.setItem("accessToken", payload?.access_token);
      if (payload?.user) {
        localStorage.setItem(
          "nickName",
          `${payload?.user?.email}`
        );
      }
    }
    state.accessToken = payload?.access_token || null;
  },
};

const actions = {
  async getStats({ commit }, payload) {
    apiService.get(
      "stats",
      {},
      (response) => {
        commit("setToken", response?.data);
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
